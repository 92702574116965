/*BODY AND HTML DETFAULTS*/

html,
body {
    /*font-family: 'Hind', sans-serif;*/
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5rem;
    background-color: #fff;
}

::-webkit-scrollbar {
    width: 0;
}

.alert {
    border-left-width: 8px !important;
}

.show-on-hover {
    display: none;
}

.hover .show-on-hover {
    display: inline;
}

/**** NAV SCROLLER ******/

.nav-scroller {
    position: -webkit-sticky;
    position: sticky;
    top: 66px;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
    background-color: var(--bs-white);
}

@media (min-width: 768px) {
    .nav-scroller {
        top: 67px;
    }
}

.nav-scroller .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.nav-scroller .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    border-bottom: 2px solid transparent;
    color: var(--bs-secondary);
    font-weight: bold;
}

.nav-scroller .nav-link.active {
    color: var(--hw);
    border-bottom-color: var(--hw);
}

.nav-scroller .nav-link:hover,
.nav-scroller .nav-link:focus {
    color: var(--hw);
}

/** PAGE HEADERS **/

.page-banner {
    padding-top: calc(58px + 4rem);
    padding-bottom: 4rem;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
}

@media (max-width: 767px) {
    .page-banner {
        padding-top: calc(58px + 3rem);
        padding-bottom: 3rem;
    }
}

.page-banner > .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.page-banner > *:not(.overlay) {
    z-index: 2;
    position: relative;
    color: var(--bs-white) !important;
}

a {
    text-decoration: none !important;
}

a[href="#myPage"] {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 1000;
    opacity: 0.5;
    display: none;
}

a[data-action="dark-mode"] {
    position: fixed;
    right: 0;
    bottom: calc(50vh - 0.75rem);
    z-index: 1000;
    opacity: 0.5;
}

a[href="#myPage"]:hover,
a[href="#myPage"]:focus,
a[data-action="dark-mode"]:focus,
a[data-action="dark-mode"]:hover {
    opacity: 1;
}

.breadcrumb-item a {
    color: var(--bs-success);
}

.breadcrumb {
    background-color: var(--bs-white);
}

.alert {
    border-left-width: 8px !important;
}

footer a {
    color: var(--bs-dark) !important;
}

footer .s-m {
    font-size: 1.2rem;
}

@media (max-width: 576px) {
    .bottom_links {
        margin-top: 0;
        margin-bottom: 1rem;
        padding-top: 0;
        padding-bottom: 0;
    }
    .bottom_links p {
        display: inline-block;
        white-space: nowrap;
        padding: 0.1rem 0.25rem;
    }
    .bottom_links p:not(:last-child):after {
        content: "|";
        margin-left: 0.5rem;
    }
}

/**************
INPUT FORM CONTROL
**************/

.selectize-input input,
span.name,
.selectize-dropdown {
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: normal;
}

span.name {
    font-size: 1.2rem !important;
    font-weight: 300;
}

.selectize-input {
    height: 100% !important;
    /* padding-top: 1rem !important;
    padding-bottom: 1rem !important; */
}

.selectize-input {
    height: 100% !important;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
}

.input-group .selectize-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.selectize-input.focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.selectize-input.has-items {
    padding-top: 0.5rem !important;
    padding-bottom: 0.55rem !important;
}

/* .selectize-control.multi .selectize-input > div {
    background-color: rgba(0, 0, 0, 0.1);
    margin-right: .5rem;
} */

.selectize-control.multi .selectize-input.has-items > div {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 2px 0.5rem 1px 0;
}

.input-group .selectize-input.has-items {
    /* padding-top: .5rem !important; */
    padding-bottom: 0 !important;
}

.selectize-control:not(.multi) .selectize-input > div {
    margin-top: 4px;
}

/* .selectize-input.has-items {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
} */

.selectize-input:not(.has-items) input {
    width: 100% !important;
}

.selectize-dropdown-content .label {
    font-weight: normal;
}

.selectize-dropdown-content > div:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

div.selectize-control {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

input:not(*[type="checkbox"]),
select,
textarea,
.input-group-addon,
.selectize-input,
.form-select,
.custom-input {
    border: none !important;
    border-radius: 0.25rem !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
    font-weight: 300 !important;
}

@media (min-width: 576px) {
    .label-sm-hide .form-field-title {
        display: none;
    }
}

.form-control,
.custom-select,
.form-select {
    font-size: 1.15rem;
    box-shadow: none;
}

.form-control-sm,
.custom-select-sm,
.form-select-sm {
    font-size: 1rem;
}
.form-control-lg,
.custom-select-lg {
    font-size: 1.3rem;
}

.form-text {
    line-height: 1.15;
    display: block;
}

.custom-control-label {
    font-size: 1.2rem;
}

.input-group-addon:not(button) {
    background-color: inherit !important;
}

button.input-group-addon {
    background-color: var(--bs-white);
    font-size: 24px;
    border: none;
}

input:focus,
select:focus,
.selectize-input:focus,
textarea:focus {
    box-shadow: none !important;
    border-width: 1px;
}

.validate:invalid {
    border-bottom: 3px solid red;
}

.invalid-feedback,
.valid-feedback {
    line-height: 1 !important;
}

.selectize-input ::placeholder {
    color: #6c757d;
}

div.row label {
    margin-bottom: 0;
}

::placeholder {
    font-style: italic;
    /* font-size: small; */
}

.form-field-title {
    font-weight: normal;
    color: var(--hw);
    display: block;
    margin-top: 0.5rem;
}

.field-explanation {
    font-size: small;
    color: var(--bs-secondary);
    display: none;
    line-height: 1;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

input:focus ~ .field-explanation,
select:focus ~ .field-explanation,
textarea:focus ~ .field-explanation {
    display: block;
}

.form-section-label {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    margin-top: 1.5rem;
    /* padding-left: .5rem; */
    /* padding-right: .5rem; */
    color: var(--bs-secondary);
}

.form-section-label:first-of-type {
    margin-top: 0.5rem;
}

.form-section-label a {
    color: var(--bs-info) !important;
}

.show-invalid input:invalid,
.show-invalid select:invalid,
.show-invalid textarea:invalid {
    border-color: var(--bs-danger);
}

form .invalid-text {
    display: none;
}

.show-invalid input:invalid ~ .invalid-text,
.show-invalid select:invalid ~ .invalid-text,
.show-invalid textarea:invalid ~ .invalid-text {
    display: block;
    line-height: 1.1;
    color: var(--bs-danger);
    margin-top: 0.25rem;
}

.modal-open .form-action-btn-container {
    visibility: hidden !important;
}

.modal-open .modal .form-action-btn-container {
    visibility: unset !important;
}

.form-action-btn-container {
    /*-ms-flex-align: center!important;
    align-items: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;*/
    /* -ms-flex-direction: column !important;
    flex-direction: column !important;
    display: -ms-flexbox!important;
    display: flex!important; */
    padding: 0 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.modal .form-action-btn-container {
    margin: 0 !important;
    padding: 0.25rem;
}

.form-action-btn-container .btn {
    width: 100%;
    margin: 0.25rem 0.25rem;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (min-width: 576px) {
    .form-action-btn-container {
        text-align: right;
    }
    .form-action-btn-container .btn {
        margin: 0.25rem 0.25rem;
        width: unset;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

/**************
wating
**************/

.loader-container,
.loader {
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    display: -ms-flexbox;
    display: flex;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.2);
}

.loader {
    background-color: rgba(0, 0, 0, 0.5);
    height: 300px;
    width: 300px;
    color: var(--bs-white);
    z-index: 1051;
}

body.loaded .loader-container,
body.loaded .loader {
    display: none;
}

/************************
Page Header
*************************/
.page-header {
    -ms-flex-align: center !important;
    align-items: flex-start !important;

    -ms-flex-pack: center !important;
    justify-content: center !important;

    -ms-flex-direction: column !important;
    flex-direction: column !important;

    display: -ms-flexbox !important;
    display: flex !important;

    margin-bottom: 0.75rem;
}

.page-header-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--hw);
}

.page-header-actionbar {
    margin-top: 0.5rem;
}

.page-header-actionbar .btn {
    padding: 0.375rem 0.75rem !important;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.page-header-actionbar.bar-sm .btn {
    padding: 0.3rem 0.65rem !important;
    font-size: 0.875rem;
    line-height: 1.5;
}

.page-header-actionbar :not(.btn-group) .btn {
    border-radius: 0.25rem;
}

.page-header-actionbar.bar-sm :not(.btn-group) .btn {
    border-radius: 0.2rem;
}

.page-header-actionbar .btn:not(.nav-link) {
    color: var(--hw);
    background-color: transparent;
    background-image: none;
    border-color: var(--hw);
}

.page-header-actionbar .btn:not(.nav-link):hover {
    color: #fff;
    background-color: var(--hw);
    border-color: var(--hw);
}

.page-header-actionbar .btn.nav-link {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
}

.page-header-actionbar .btn.nav-link:hover {
    color: #fff;
    background-color: var(--bs-info);
    border-color: var(--bs-info);
}

.page-header-actionbar .btn.nav-link.active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-header-actionbar .btn-group:not(:last-child) {
    margin-right: 0.5rem;
}

.page-header-actionbar .btn-group .btn:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.page-header-actionbar .btn-group .btn:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.page-header-actionbar > .btn {
    border-radius: 2rem;
}

@media (min-width: 768px) {
    .page-header {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;

        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .page-header-actionbar {
        margin-top: unset;
    }
}

@media (max-width: 767px) {
    .page-header-title {
        margin-bottom: 0.5rem;
    }
    .page-header-actionbar {
        margin-bottom: 0.5rem;
    }
}

/************************
Accordion
*************************/

.acc-con > .card {
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.acc-con > .card > .card-header.con-h {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.5rem !important;
    color: var(--bs-secondary);
}

.acc-con > .card > .card-header.con-h > div:first-of-type {
    -ms-flex-align: center !important;
    align-items: center !important;

    display: -ms-flexbox !important;
    display: flex !important;

    -ms-flex-pack: justify !important;
    justify-content: space-between !important;

    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.acc-con > .card > .card-header.con-h > div > a {
    font-weight: bold;
    color: var(--bs-secondary);
}

.acc-con > .card > .card-header.con-h.active {
    color: #fff;
    background-color: var(--bs-info);
    background: #56ccf2; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2f80ed, #56ccf2); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #2f80ed,
        #56ccf2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.acc-con > .card > .card-header.con-h.active .text-muted a {
    display: none;
}

.acc-con > .card > .card-header.con-h.active * {
    color: #fff !important;
}

.acc-con > .card > * > .card-body {
    padding: 0.5rem 0.25rem;
}

@media (min-width: 576px) {
    .acc-con > .card > * > .card-body {
        padding: 0.5rem 0.75rem;
    }
}

#notification_button {
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    z-index: 5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    /* padding: 0.4rem 0.3rem !important; */

    .badge {
        position: absolute;
        top: 6px;
        right: 6px;
    }

    @media (min-width: 576px) {
        bottom: 2rem;
        right: 2rem;
    }
}


.app_notification_group .list-group-item {
    line-height: 1.25;
    padding-top: .75rem;
    padding-bottom: .75rem;
}
