// $theme-colors: (
//     "primary": #0D9AD8,
//     "psh": #e63e32
// );
// $enable-gradients: true;
$enable-shadows: true;
$accordion-button-active-color: var(--bs-teal);
$accordion-button-focus-box-shadow: none;
$grid-gutter-width: 1rem;

@import "./../../node_modules/bootstrap/scss/bootstrap.scss";

.modal {
    .modal-header {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }
}

.content-wrapper .table {
    font-size: 90%;
}

// full calendar

.fc {
    font-size: 13.5px;
    .fc-daygrid-block-event .fc-event-title {
        padding-top: 0;
        padding-bottom: 0;
    }
    .fc-list-event-dot {
        display: none;
    }
    th,
    td {
        border: 1px solid var(--bs-gray-300) !important;
    }
}
